import { render, staticRenderFns } from "./HistoryUjian.vue?vue&type=template&id=48655bd0&scoped=true"
import script from "./HistoryUjian.vue?vue&type=script&lang=js"
export * from "./HistoryUjian.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48655bd0",
  null
  
)

export default component.exports